.container-btn {
    margin-bottom: 100px;
    padding: 10px;
}

.btn-group-terms {
    position: absolute;
    padding: 10px;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, .50);;
    //background-blend-mode: overlay;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.15);

    button {
        margin-right: 10px;
    }

    button:last-child {
        margin-right: 0;
    }
}

.btn-terms {
    background-color: white;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    letter-spacing: 1.5px;
    padding: 5px;

}

.btn-contrast {
    background-image: url('../assets/img/btn-contrast.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.wrapper-terms {
    min-height: 1000px;
 }