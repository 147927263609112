//fonts
@font-face {
    font-family: "Inter-Regular";
    src: url('../assets/fonts/inter/Inter-Regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: "Inter-Bold";
    src: url('../assets/fonts/inter/Inter-Bold.ttf');
    font-weight: bold;
}

* {
    font-family: "Inter-Regular";
}


:root {
    --background: white;
    --text-primary: #212529;
    --glass: blur(0px);
}

[data-theme = 'dark'] {
    --background: #1F1F1F;
    --text-primary: #FFFFFF;
    --glass: blur(50px);
}

body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    transition: all .5s ease-in-out;
    background-color: var(--background);
    color: var(--text-primary);
    
    .btn-group-terms {
        backdrop-filter: var(--glass);
    }
}

#root {
    height: 100vh;
    max-width: 100vw;
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: Inter-Bold;
}

h3 {
    font-size: 28px !important;
    font-weight: 600 !important;
}

h4 {
    font-size: 20px !important;
    font-weight: 700 !important;
}


h6 {
    font-size: 10px !important;
}


.middle_screen {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    //margin: 0 auto;
    //margin-top: 140px;
    //margin-bottom: -180px;
    //height: 100vh;
}