.div_navbar {
    display: flex;
    width: 100%;
    background-color: #fff;
}

.div_navbar2 {
    margin-right: 150px;
}

.navbar {
    display: flex;
    position: fixed;
    height: auto;
    background-color: #fff;
    justify-content: center;
    width: 100%;
    //max-width: 1440px;
    padding: 0;

    ul {
        li {
            list-style: none;
            padding-top: 30px;
            padding-bottom: 30px;
            padding-right: 20px;
            padding-left: 20px;
            font-size: 12px;
            font-weight: 600;
            width: 165px;
            text-align: center;
            line-height: 1.0;
            //margin-right: 10%;

            &:hover {
                cursor: pointer;
            }

            a {
                color: #6B7280;
                text-decoration: none;
            }
        }
    }
}

.nav-wrapper {
    width: 100%;
}

.row-nav {
    //margin-left: 4.5% !important;
    //margin-right: 3.5% !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.nav_img {
    width: 130px;
}

.nav_img_footer {
    width: 202px;
}

.nav_img_footer_rrss {
    width: 60px;
}

.nav_img_footer_trip {
    width: 250px;
}

.navbar2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;

}
select.form-select {
    background-color: transparent !important;
    background-image: url("../assets/img/down-filled-triangular-arrow.png") !important;
    background-position: right 1.3rem center !important;
    background-size: 8px;
    border: #fff !important;
    box-shadow: none !important;
    color: #6B7280 !important;
    font-weight: 600 !important ;
    outline: none !important;
    font-size: 12px;
    width: fit-content;
    margin-right: 25px;
    
    .dropdown-toggle:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

#dropdown-basic {
    background-color: #fff !important;
    border: none !important;
    color: #6B7280;
    box-shadow: none;
    font-size: 12px;
    font-weight: 600;
}

.dropdown-menu {
    border: none !important;
    background-color: #fff;
}

.dropdown-item {
    background-color: #fff !important;
    border: none;
    color: #6B7280;
    box-shadow: none;
    font-size: 12px;
    font-weight: 600;
}

.dropdown-toggle::after {
    display: none;
  }

.nav-item {
    color: #6B7280;
}

.select-item {
    background-color: $primary;
    font-family: Inter-Bold;
    font-size: 14px !important;
    font-weight: 700 !important;
    letter-spacing: 1.25px;
    color: #fff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    min-width: 220px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 !important;
    align-items: center;
}

.menu-burguer {
    display: none;
}

@media (max-width: 1024px) {
    .menu-burguer {
        display: block;
    }
    .div_navbar2 {
        margin-right: 0px;
    }
}

@media (max-width: 775px) {
    .col-header {
        width: auto !important;
    }
    .select-item {
        display: none;
    }

    .navbar {
        flex-wrap: nowrap;
    }
}

@media (max-width: 460px) {
    .menu-burguer {
        width: 50% !important;
    }

    .nav_img {
        width:80px;
        margin-top: 10px;
    }
}