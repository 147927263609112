.box_form {
    margin-top: 35px;
    //display: flex;
    align-content: center;
    justify-content: center;
    //min-height: 51vh;
}

.input_date {
    font-family: 'FontAwesome','Inter-Regular';
    display: flex;
    align-items: center;
}

.input_form {
    background-color: white;
    border: 1px solid $primary;
    color: #737373;
    height: 48px;
    font-size: 16px;
    //padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    //margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.input-icon::after {
    font-family: 'FontAwesome';
    content: "\f002";
    position: absolute;
    right: 10px;
}

.input-radio {
    width: 20px;
    outline: none;

    &:checked {
        accent-color: $primary;
    }
}

.input-wobor {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.span-desc {
    margin-left: -60px;
}

.input-inside {
    width: 85%;
    border: 0px;
    height: 100%;
}

.select-inside {
    width: 95%;
    border: 0px;
    height: 100%;
    background-color: transparent;
}

.select_form {
    appearance: none;
    background: url('../assets/img/right-arrow.png') no-repeat 97%;

    &:active {
        background: url('../assets/img/down-arrow.png') no-repeat 97%;
    }
    transition: background .1s ease;
    -webkit-transition: background .1s ease;
    -moz-transition: background .1s ease;
    -ms-transition: background .1s ease;
    -o-transition: background .1s ease;
}

.select_form_2 {
    appearance: none;
    padding: 5px;
}


#rfs-btn {
    font-size: 14px !important;
    border: 0px;
    color: #737373;
    margin: 0px;
    padding: 0px;
    height: auto;
}

.counter {
    width: 32px;
    height: 32px;
    border-color: rgba(107, 114, 128, 0.2);
}

.btn_counter {
    background-color: rgba(107, 114, 128, 0.2);
    border: 0px;
    width: 32px;
    height: 32px;
}

.btn-form {
    background-color: transparent;
    border-radius: 20px;
    border: 2px solid  $primary;
    color: $primary;
    font-size: 14px;
    font-family: 'Inter-Bold', 'Helvetica Neue', Helvetica, Arial;
    padding: 10px 40px 10px 40px;
    letter-spacing: 1.5px;
    transition: background-color .3s ease;

    /*&:hover {
        background-color: $primary;
        color: white
    }*/
}

.btn-form-active {
    background-color: $primary;
    color: white;
}

.btn-form-disabled {
    background-color: lightgrey;
    color: white;
    border-color: lightgrey;

    &:hover {
        background-color: lightgrey;
        color: white;
        cursor: not-allowed;
    }
}

.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
}

.wrapper {
    min-height: 925px;
 }

 .link-span {
    text-decoration: none;
    color: $primary;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1.25px;

    &:hover {
        box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
        font-weight: bolder;
    }
}

.sd_respon,
.sd_respon_2 {
    font-size: 12px;
}

.select-tipo-ent {
    grid-gap: 0px;
}

.input-paymethod {
    margin-left: 30px;
    cursor: pointer;
}

.dark-pay {
    background-color: $primary;
    color: white;
}

.link {
    color: black;
    cursor: pointer;
    font-weight: bold;

    &:hover {
        color: black;
    }
}

@media (max-width: 1200px) {
    .btn-form {
        margin-bottom: 10px;
    }
}

@media (max-width: 768px) {
    .select-date-hour {
        display: block;
    }

    .select-tipo-ent {
        display: grid;
        grid-template-columns: 1.5fr 2fr 2fr 0.5fr 2fr;
    }
}

@media (max-width: 731px) {
    .select-tipo-ent {
        display: grid;
        grid-template-columns: 1.5fr 2fr 0.5fr 1.5fr;
    }

    .sd_respon {
        display: none;
    }
}

@media (max-width: 662px) {
    .select-tipo-ent {
        display: grid;
        grid-template-columns: 1.5fr 0.5fr 2.5fr;
    }

    .sd_respon_2 {
        display: none;
    }

    .input-radio {
        display: none;
    }

    .input-paymethod {
        margin-left: 0px;
    }

    /* .form-check {
        padding: 10px;
    } */

    .btn-form {
        width: 100%;
    }

    .title-total {
        font-size: 14px;
    }
}
