.contain_footer {
    bottom: 0;
    //box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
    padding: 0px;
    margin-bottom: 0px !important;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 307px;
}

.footer {
    display: flex;
    justify-content: center;
    bottom: 0;
    background-color: $primary;
    color: #fff;
    //left: 0;
    width: 1440px;

    h4 {
        font-size: 18px;
        font-family: sans-serif;
    }

    h6 {
        margin-bottom: 0px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 12px;
        padding-right: 12px;
    }

    p {
        line-height: 27px;
        font-size: 12px;
        font-weight: 600;
    }

    a {
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }
}

.box_footer {
    margin-top: 15px;
    width: 94%;
}

.box_trip {
    max-width: 290px;
}

.box_rrss {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.img-mob {
    display: none;
}

@media (max-width: 1170px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }

    .box_footer {
        //margin-left: 25px;
        padding-left: 50px;
        width: 100%;
    }

    .img-desktop {
        display: none;
    }

    .img-mob {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
    }

    /* .box_footer {
        .col-md:first-child {
            margin-left: 50px;
        }
    } */
}

@media (max-width: 1024px) {
    a {
        font-size: 10px !important;
    }
}

@media (max-width: 768px) {
    .box_footer {
        padding-left: 0px;
        padding-right: 0px;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: 310px;
        //align-items: center;
        //padding-left: 70px;
        // grid-template-columns: auto auto auto;
        // grid-template-rows: auto auto;
        // margin: 0 auto;
    }

    .nav_img_footer_rrss {
        width: 50px;
    }

    .nav_img_footer_trip {
        width: 200px;
    }

    .nav_img_footer {
        width: 165px;
    }

}

@media (max-width: 565px) {
    h4 {
        font-size: 25px !important;
    }
    .box_footer {
        width: 260px;
    }
    .box_rrss {
        display: flex;
        justify-content: space-between;
    }
}