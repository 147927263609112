@import '_base.scss';
@import '_settings.scss';

@import '_div.scss';
@import '_navbar.scss';
@import '_header.scss';

@import '_card.scss';
@import '_form.scss';

@import '_terms.scss';

@import '_footer.scss';

//FontAwesome
@import '../../node_modules/font-awesome/css/font-awesome.min.css';


//DatePicker
@import "react-datepicker/dist/react-datepicker.css";