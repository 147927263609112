.container {
    display: flex;
    justify-content: space-between;
}

.title {
    margin-top: 130px;
    max-width: 1440px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 40px;

    p {
        font-size: 16px;
        margin-bottom: 0.60rem;
    }
}

.box_main {
    // align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 375px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 1em;
    max-width: 1440px;
    align-items: center;
    align-content: space-between;
}

.box-container {
    width: 100%;
}

.box_footer {
    display: flex;
    justify-content: space-around;
    //width: 100%;

    /* .col-md:first-child {
        margin-left: 100px;
    } */
}

.wborder {
    display: flex;
    align-items: center;
    border: 1px solid $primary;
    //padding: 7px 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
    gap: 10px;
    margin-left: 0px;
    margin-right: 0px;
    height: auto;
}

.back-primary {
    background-color: $primary;
    color: white;
    font-family: 'Inter-Bold';
}

.btn-inv {
    color: white;
}

.img_trj {
    height: 34px;
}

.text-total {
    font-size: 18px;
    font-family: 'Inter-Bold';
}

.tips {
    font-size: 0.9em;
}

@media (max-width: 978px) {
    .box-container {
        width: 95%;
    }
}

@media (max-width: 768px) {
    .box-container {
        width: 100%;
    }

    .title {
        padding-left: 20px;
        padding-right: 20px;
    }

    .box_main {
        // align-items: center;
        padding-left: 20px;
        padding-right: 20px;
    }
}