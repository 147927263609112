.card-container {
    display:flex;
    justify-content: space-between;
    //gap: 50px;
}

.card {
    width: 50%;
    border: 0px solid #ededed;
    //border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    padding: 20px;
    //margin: 20px;
    //min-height: 240px;

    transition: background-color .5s ease;

    p {
        font-size: 14px;
        //margin-bottom: 0.60rem;
    }

    // small {
    //     font-size: 0.8rem;
    // }

    //&:hover {
    //    background-color: lighten($color: #9e9e9e, $amount: 30);
    //    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
    //}
}

.card-visit {
    padding-top: 50px;
    padding-bottom: 50px;
}

.card-head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h5 {
        margin-left: 10px;
    }
}

.icon-card {
    width: 64px;
    height: 64px;
}

.card-body {
    margin-left: 11%;
    padding: 5px;
    margin-bottom: 30px;
}

.content-body{
    color: #6B7280;
    line-height: 12px;
    letter-spacing: 0.25px;

}

.content-body-columns {
    display: flex;
    flex-wrap: wrap;
    gap: 10%;

    p {
        margin-bottom: 0px;
    }
}

.bolder {
    font-size: 18px !important;
    font-weight: 700;
    margin-bottom: 50px !important;
    color: $text !important;
}

.card-footer {
    border: 0px;
    background-color: transparent;
    text-align: right;

    a {
        letter-spacing: 1.25px;
        text-decoration: none;
        color: $primary;
        cursor: pointer;
        font-weight: bold;
    }
}


@media (max-width: 834px) {
    .card-container {
        flex-direction: column;
    }

    .card {
        width: 100%;
        margin-bottom: 30px;
     }

    // .card-body {
    //     margin-left: 0%;
    // }

    // .content-body-columns {
    //     flex-direction: column;
    // }
}